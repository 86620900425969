

h1{
    font-family: 'Krona One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 1.2;

    letter-spacing: -0.05em;
    mix-blend-mode: normal;
    @media(max-width:767px){
        font-size: 32px;
        line-height: 38px;
    }
}

h2{
    font-family: 'Krona One', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 47px;
    text-align: center;
    text-transform: capitalize;
    @media(max-width:767px){
        font-size: 24px;
        line-height: 30px;
    }
}

h3{
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
}

p{
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    @media(max-width:991px){
        font-size: 18px;
        line-height: 25px;
    }
}


.banner{
    position: relative;
    font-family: 'Krona One', sans-serif;
    
    padding: 0px;
    color: #000;
    @media(min-width:992px){
        padding: 107px 20px 0;
        background-image:url(../media/images/hero-img.png);
        background-position: center center;
        background-size: cover;
        height: 100vh;
        max-height: 95vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        &:before{
            content:"";
            height:100%;
            width:100%;
            position: absolute;
            top:0; left:0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
        }
    }
    @media(min-width:1150px){
        max-height: 80vh;
    }
    @media(max-width:991px){
        .submit-form{
            form{
                border-color:#000;
                input{
                    color:#000;
                    &::placeholder,
                    &:-ms-input-placeholder,
                    &:-webkit-input-placeholder { 
                        color:#000;
                    }
                }
                svg{
                    path{
                        fill: #000;
                    }
                    
                }
            }
        }
    }
    >*{
        position: relative;
    }
    .container{
        max-width:1226px;
        @media(max-width:991px){
             padding: 38px 20px 42px;
        }
    }
    img{
        display: none;
        @media(max-width:991px){
            display: inline-block;
            text-align: center;
            width: 100%;
            max-height: 400px;
            object-fit: cover;
            object-position: center 73%;
        }
    }
    h1{
        max-width: 883px;
    }
    p{
        max-width: 686px;
        margin:43px 0;
        @media(min-width:992px){
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
        }
    }
}

