.section1{
    padding:41px 0px 54px;
    background: #E5E5E5;
    color: #121111;
    @media(min-width:768px){
        padding:52px 0px 152px;
    }
    h2{
       color: #121111;
       max-width:650px;
       padding: 0 20px;
       margin:0 auto;
       @media(min-width:992px){
          max-width:1216px;
       }
    }
    p{
        padding: 0 20px;
        max-width:650px;
        margin: 24px auto 36px;
        @media(min-width:992px){
          max-width:1216px;
       }
    }

    .section-grid{
        display: grid;
        grid-template-columns: 1fr;
        @media(min-width:992px){
            grid-template-columns: 1fr 50%;
            justify-content: center;
            align-items: center;
            grid-gap: 1em;
            padding: 2em 1em 0;
        }
        @media(min-width:1200px){
            grid-gap: 3em;
            padding:0;
        }
        >div{
            >img{ 
                width:100%; max-width:inherit;
                @media(max-width:991px){
                    max-height:400px;
                    object-fit: cover;
                    object-position:center;
                }
            }
            .section-grid{
                grid-gap: 0em 10%;
                grid-template-columns: 40% 40%;
                margin-top: 10%;
            }
            .item{
                text-align: left;
                img{width:inherit; max-width:100%; display: inline-block;}
                
                h3{color: #FF9E1B; margin:13px 0 11px;}
                p{color: #121111; font-size: 16px; line-height: 22px; padding:0;}

                &:nth-child(4n+2){ h3{color:  #D3631A;} }
                &:nth-child(4n+3){ h3{color:  #D3631A;} }
                &:nth-child(4n+4){ h3{color:  #A7271A;} }
                @media(max-width:991px){
                    padding:2em 2em;
                    text-align: center;
                    max-width:450px;
                    margin:0 auto;
                    
                }
            }
        }
    }
}

.section2{
    background-image:url(../media/images/quote-img.png);
    background-size: cover;
    background-position: center;
    padding:38px 20px 38px;
    @media(min-width:992px){
        padding:108px 0 91px;
    }
    .container{
        max-width: 1205px;
        text-align: center;
        span{
            font-family: 'Krona One', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 88px;
            line-height: 110px;

            color: #FFFFFF;
        }
        h2{
            padding:108px 0;
            color:white;
            @media(max-width:991px){
               padding: 16px 0 47px;
                font-size: 24px;
                line-height: 30px;
            }
        }

        p{
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 38px;
            text-align: center;

            color: #FFFFFF;
        }
    }
}