#menu {

	.container{
		max-width:1340px;
	}
	.links {
		a {
			font-family: 'Manrope', sans-serif;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 25px;
			text-transform: uppercase;
			margin: 0 0 0 3em;

			color: #FFFFFF;
		}
	}
	@media(max-width:991px){
		background: #000000;
		text-align: center;
		.logo{
			width: 100%;
			justify-content: center;
		}
		img{
			max-height: 62px;
		}
	}
	@screen lg {
		img{
			max-height: 82px;
			transition: max-height 0.2s linear;
		}
		background:transparent;
		transition: all 0.2s linear;
	}
	&.scrolling {
		@screen lg{
			img{
				max-height: 50px;
				transition: max-height 0.2s linear;
			}
			background:black;
			transition: all 0.2s linear;
		}
	}
}
