
.section4{
    background-image: url(../media/images/section3.jpg);
    background-size:cover;
    background-position: center;
    padding:49px 20px 52px;
    @media(min-width:768px){
        padding:163px 20px;
    }

    h2{
        max-width:884px;
    }
}

