.footer{
    //-
    background-color:white;
    .top{
        background: #346D83;
        p,a{
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            text-align: center;
            padding:12px 10px;
            color: #FFFFFF;
            text-decoration: none;
            @media(max-width:991px){
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
    .bottom{
        background: #000000;
        p{
            text-align: center;
           font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            padding:23px 10px 19px;
            color: #FFFFFF;
            @media(max-width:992px){
                font-size: 12px;
                line-height: 16px;
                padding:17px 10px 15px;
            }
        }
    }
}