@tailwind base;
@tailwind components;

@import "_menu";
@import "styles";
@import "_banner";
@import "_section1";
@import "_section3";
@import "_section4";
@import "_footer";
@import "_form";

@tailwind utilities;
