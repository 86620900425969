body {
	font-smooth: always;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

.container{
	width:100%;
	max-width: 1226px;
	margin:0 auto;
}
