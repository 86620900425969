.section3{
    padding:58px 20px 50px;
    @media(min-width:768px){
        padding:108px 20px 77px;
    }
    .container{
        max-width:1106px;
        >h2,>p{
            max-width: 982px;
            margin:0 auto;
        }
        >p{
            margin:19px auto 39px;
        }
        .section-grid{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 2em;
            @media(orientation:landscape){
                grid-template-columns: 1fr 1fr;
            }
            @media(min-width:768px){
                grid-template-columns: 1fr 1fr;
            }
            @media(min-width:1200px){
                 grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            .item{
                .img-container{
                    position: relative;
                    @media(max-width:768px){
                        width: 80%;
                        margin:0 auto;
                    }
                    >img{
                        width:100%;
                        max-height: 300px;
                        object-fit: cover;
                        object-position: center;
                    }
                    svg{
                        position: absolute;
                        left:9px; bottom:6px;
                    }
                }
                h3{
                    color:#962317;
                    margin:27px 0 9px;
                    padding:0;
                    @media(max-width:991px){
                        font-size: 28px;
                        line-height: 38px;
                        text-align: center;
                    }
                }
                &:nth-child(4n+2){
                    h3{  color: #B74825; }
                }
                &:nth-child(4n+3){
                    h3{  color: #D5702E; }
                }
                &:nth-child(4n+4){
                    h3{  color: #FCA52F; }
                }
                p{
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    @media(max-width:991px){
                        text-align: center;
                    }
                }
            }

        }
    }
}