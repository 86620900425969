.submit-form{
    margin-top:50px;
    margin-bottom: 65px;
    display: block;
    max-width: 360px;
    @media(max-width:768px){
        max-width: 285px;
        margin:50px auto 0;
    }
    .msg{
        background:#888;
        color:#fff;
        border-radius:10px;
        max-height:0px;
        padding:0;
        overflow: hidden;
        transition: max-height .1s, padding .1s;
        -webkit-transition: max-height .1s, padding .1s;

        &.error{
            background:#f57272;
        }
        &.success{
            background:#5ad07f;
        }
        &.active{
            padding:1px;
            max-height:500px;
            transition: max-height 1s, padding .1s;
            -webkit-transition: max-height 1s, padding .1s;
        }
        p{
            margin: 16px 25px;
            font-size: 14px;
            line-height: 16px;
        }
    }
    form{
        &.hidden{
            overflow:hidden;
            max-height:0px;
            transition:max-height .5s;
        }
    }
}

form{
    border-bottom:2px solid #F8F8F8;
    display: inline-block;
    
    input{
        background:transparent;
        border:0;
        padding:5px 35px 35px  0px;
        @media(max-width:768px){
           padding:5px 35px 18px  0px; 
        }
        &[type=email]{
            font-family: 'Manrope', sans-serif;
            font-weight: normal;
            font-size: 20px;
            line-height: 27px;
            color: #FFFFFF;
            outline: 0;
            width: 339px;
            &::placeholder,
            &:-ms-input-placeholder,
            &:-webkit-input-placeholder { 
                color: #FFFFFF;
            }
            @media(max-width:768px){
                font-size: 14px;
                line-height: 19px;
                width: 269px;
            }
        }
    }
    *[type=submit]{
        border:0;
        background-color:transparent;
        outline:0;
       
        svg{
            transform: translate(0px, 4px);
            transition: transform .2s;
            -webkit-transition: transform .2s;
            path{
                fill:white;
            }
        }
        @media(min-width:992px){
            &:hover{
                svg{
                    transform: translate(5px, 4px);

                    transition: transform .5s;
                    -webkit-transition: transform .5s;
                }
            }
            
        }
    }
}